import { generateUniqueId } from '../utils/generateUniqueId'
import useBroadcastChannelNative from './useBroadcastChannelNative'

export interface IActiveTabWatcherSettings {
  onUpdateCallback?: Function
}

const UPDATE_CALLBACK_TIMEOUT = 500 // Таймаут необходим, чтобы изменения успели примениться на стороне сервера
const workingAreaKey = generateUniqueId() // Ключ необходим для идетификации вкладки на которой произошли изменения

export function useActiveTabWatcher(settings?: IActiveTabWatcherSettings) {
  const messageBroker = useBroadcastChannelNative('global')

  const applyActiveTabChanges = () => {
    messageBroker.send({
      id: 'change',
      payload: { key: workingAreaKey },
    })
  }

  messageBroker.on('change', async (payload) => {
    if (payload.key === workingAreaKey) // Исключаем случай когда изменение произошло в активной вкладке
      return

    if (settings?.onUpdateCallback)
      setTimeout(settings.onUpdateCallback, UPDATE_CALLBACK_TIMEOUT) // Таймаут необходим, чтобы изменения успели примениться на стороне сервера
  })

  return {
    applyChanges: applyActiveTabChanges,
  }
}
